




import * as React from "react"
import Layout from "../components/layout"


const ServicesPage = () => {
    return (
      <Layout pageTitle="Services">




<h2>Services</h2>


<h3>General Contractor</h3>
<ul>
<li>★Residential</li>
<li>★Commercial</li>
<li>★New Construction</li>
<li>★Remodel</li>
<li>★Restoration</li>
</ul>





<h3>Owner’s Representation</h3>
<ul>
<li>★Individual</li>
<li>★Institutional</li>
<li>★Project Oversight</li>
<li>★RFP and Response Management</li>
<li>★Estate Property Management</li>
</ul>
</Layout>
    )}

    export default ServicesPage